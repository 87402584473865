<template>
    <KCourse loader-class="MBcont"
             chapter-id=88
             chapterPayment="/Membership/Beginner2/MBCourseBeginnerChapterPayment.vue"
             course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Так же у вас есть возможность  сшить съемный костюм для своей девочки. Получите навык работы с историческим платьем в стиле ампир XIX века 
                и создадите таинственный образ Наташи Ростовой или Эленн Безуховой, Эммы или Элизабет Беннет... Великий век неповторимые женские образы ) 
                и все это можно сделать по курсу <a @click="buyCourse" href="#">«Давай оденем куклу»</a> а это совсем другой уровень подхода к своей работе!</p>

            <p>Курс для вас будет доступен всего за 2650 руб вместо 3500 ₽. <a @click="buyCourse" href="#">Купить</a> </p>
            <h3>1. Создаем выкройку (упрощенный метод)</h3>
        </div>
                
        <VideoView video-id="b7aa33c3917844c18a23b6b9ed4e280c"/>

        <div class="MBtextcont">
            <h3>2. Создаем выкройку (продвинутый вариант)</h3>
        </div>

        <VideoView video-id="2225521b38294771953232d5aec31020"/>

        <div class="MBtextcont">
            <h3>3. Выкраиваем</h3>
        </div>

        <VideoView video-id="9a6cba749da5447784afa3e912077104"/>

        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Найти ткань, кружева</li>
                <li>Смоделировать платье</li>
                <li>Выкроить</li>
            </ol>

           <h3>Время выполнения: 3 часа</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";
    import {createOrder} from "@/service/api";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
          buyCourse() {
            createOrder('4', 'course')
              .then(response => {
                let orderId = response.data.data.order_id
                this.$router.push('/order/'+orderId)
              })
          },
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>


